@font-face {
    font-family: "Antique Olive Nord";
    src: url("./../fonts/Antique\ Olive\ Nord\ D\ Regular.ttf");
}

@font-face {
    font-family: "Karla Bold";
    src: url("./../fonts/Karla-Bold.ttf");
}

@font-face {
    font-family: "Karla Regular";
    src: url("./../fonts/Karla-Regular.ttf");
}