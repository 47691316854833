.rewindIcon .vjs-icon-placeholder:before {content: "\f0e4"; /* Font Awesome rewind icon */
    font-family: "Font Awesome 5 Free"; /* Specify the Font Awesome font family */
    font-weight: 900;
    background-repeat: no-repeat;
    position: relative;
  z-index: 1;
    background-color: transparent;
    color: #ffffff;
    font-size: 50px;
  }
  
  .fast-forward-icon .vjs-icon-placeholder:before {
    content: "↷";
    font-weight: bold;
    font-size: 50px;
  }
  
  .fast-forward-icon:hover {
    color: #ffffff;
  }
  
  .rewindIcon:hover {
    color: #ffffff;
  }
  
  .fast-forward-icon:hover {
    cursor: pointer;
  }
  
  .rewindIcon:hover {
    cursor: pointer;
  }