/* ------------------- Animations ------------------- */

@keyframes expand {
    from {
        width: 0%;
    }
    to {
        width: max;
    }
}

@keyframes expand-vertical {
    from {
        height: 0%;
    }
    to {
        height: max;
    }
}

@keyframes fade-in {
    from {
        opacity: 0%;
    }
    to {
        opacity: 100%;
        visibility: visible;
    }
}

@keyframes appear {
    from {
        visibility: hidden;
    }
    to {
        visibility: visible;
    }
}

@keyframes blink {
    0% {
        opacity: 0.25;
    }
    50% {
        opacity: 1;
    }
    to {
        opacity: 0.25;
    }
}

@keyframes blinkAndGrow {
    0% {
        opacity: 1;
        transform: scale(1.1);
    }
    50% {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1.1);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}